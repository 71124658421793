/* .brandkart-gradient {
  background-image: url(../../assets/images/banner.jpg),
    url(../../assets/images/restbg.jpg);
  background-repeat: no-repeat, no-repeat;
  background-position: top 0, top 99vh;
  background-size: 100vw 99vw;
} */

.brandkart-gradient {
  /* background-color: aquamarine; */
  background-image: url(../../assets//images/banner.jpg),
    url(../../assets/images/restbg.jpg);
  background-repeat: no-repeat, round;
  background-size: 100vw 99vw, 100vw 142vw;
  background-position: left 0px top 0px, left 0px top 99vw;
}
