.get-verified-box {
  background: rgb(105, 89, 207);
  background: linear-gradient(
    63deg,
    rgba(105, 89, 207, 1) 35%,
    rgba(73, 144, 247, 1) 100%
  );

  /* background-color: #f4f9ff; */

  border-radius: 80px;
}

/* @media only screen and(max-width: 480px) {
  .get-verified-box {
    border-radius: 80px;
  }
} */
