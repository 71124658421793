.getstarted {
  background-image: url(../../assets/images/banner.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  /* background-position: left 0px; */
}

.gs-content .icon-des-box p {
  font-size: 20px;
  line-height: 46px;
  font-weight: 600;
  color: var(--color-text);
}

.gs-btn {
  margin-top: 87px;
  background-color: #6e53cb;
  border-radius: 10px;
  font-size: 20px;
  font-weight: 600;
  padding: 16px 40px;
  line-height: 24.2px;
  color: white;
}

#register-btn {
  margin-top: 32px;
  background-color: #6e53cb;
  border-radius: 10px;
  font-size: 20px;
  font-weight: 600;
  padding: 16px 40px;
  line-height: 24.2px;
  color: white;
}

#proceed-btn {
  margin-top: 32px;
  background-color: #37cfd2;
  border-radius: 10px;
  font-size: 20px;
  font-weight: 600;
  padding: 16px 40px;
  line-height: 24.2px;
  color: white;
}

#jn-heading {
  margin-top: 30px;
  font-size: 24px;
  /* line-height: 28.3px; */
  color: var(--color-heading);
  font-weight: 600;
}

#jn-desc {
  margin-top: 10px;
  /* margin-bottom: 10px; */
  font-size: 16px;
  color: var(--color-heading);
}

.ib-headings {
  margin-top: 26px;
  color: var(--color-heading);
  font-size: 14px;
  line-height: 20px;
}

#full-name-input {
  margin-top: 8px;
  width: 92%;
  border: 1px solid #c9c9c9;
  border-radius: 8px;
  height: 57px;
  padding: 0 16px;
  /* background-color: #6e53cb; */
}

#full-name-input:focus {
  border: 1px solid #1b49ea;
}

.splitform-input {
  margin-top: 8px;
  width: 85%;
  border: 1px solid #c9c9c9;
  border-radius: 8px;
  height: 57px;
  padding: 0 16px;
}

.jn-formsplit {
  display: flex;
  /* background-color: greenyellow; */
}

.jn-formsplit .left {
  flex: 50%;
  /* background-color: red; */
}

.jn-formsplit .right {
  flex: 50%;
  /* background-color: green; */
}

#agreement-cb {
  margin-top: 31px;
  font-weight: 400;
}

#dob-picker {
  margin-top: 10px;
}
