.services {
  /* background-color: #eeeeee; */
}

.services .line-1 {
  /* background-color: red; */
  display: flex;
  justify-content: center;
}

.services .line-1 h3 {
  color: var(--color-purple);
  font-size: 20px;
  line-height: 24.2px;
  font-weight: 600;
  text-transform: uppercase;
}

.services .line-2 {
  /* background-color: green; */
  margin-top: 21px;
  display: flex;
  justify-content: center;
}

.services .line-2 p {
  color: var(--color-subheading);
  font-size: 40px;
  font-weight: 700;
  line-height: 48.41px;
}

.carousel-container {
  /* margin-top: 90px; */
  display: flex;
}

.carousel-container-left {
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  flex: 50%;
}

.carousel-container-right {
  /* background-color: lightgreen; */
  flex: 50%;
  display: flex;
  /* align-items: center; */
}

.service-type {
  /* width: 80%; */
  /* display: flex; */
  /* border-bottom: 0.5px solid rgba(0, 0, 0, 0.4); */
}

.service-type div:nth-child(1) {
  /* background-color: aqua; */
  flex: 15%;
}

.service-type div:nth-child(2) {
  /* background-color: rebeccapurple; */
  flex: 75%;
}
.service-type div:nth-child(3) {
  /* background-color: orange; */
  flex: 15%;
}

.service-type .title p {
  font-size: 16px;
  font-weight: 400;
  color: black;
  line-height: 23px;
}

.service-type .title h2 {
  cursor: pointer;
}

.services-card {
  /* position: relative; */
  /* width: 100%;
  height: 100%; */
}

.services-card img {
  /* position: relative; */
  /* top: -35px; */
}

/* .services-card p {
  margin-left: 2rem;
  color: var(--color-text);
  width: 82%;
  font-size: 20px;
  line-height: 36px;
} */
