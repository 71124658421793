.testimonials .swiper {
  background-color: transparent;
}

.testimonials .swiper-slide {
  background-color: transparent;
  height: min-content;
  display: flex;
  align-items: center;
}

.swiper-button-next {
  height: 30px;
  overflow: hidden;
  /* background-color: red; */
}

.swiper-button-prev {
  height: 30px;
  overflow: hidden;
  /* background-color: red; */
}

@media screen and (max-width: 640px) {
  .swiper-button-next {
    display: none;
    height: 30px;
    overflow: hidden;
  }

  .swiper-button-prev {
    display: none;
    height: 30px;
    overflow: hidden;
  }
}
