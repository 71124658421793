@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --font-family: "Inter", sans-serif;

  --color-purple: #6e53cb;
  --color-heading: #1d252d;
  --color-subheading: #00265f;
  --color-text: #1e2228;
  --color-footer: #031b34;
  --color-blog: #042c54;
  --color-subtext: #ff8a71;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

.container {
  width: 100vw;
  height: 100vh;
}

a {
  color: unset;
  text-decoration: none;
}
