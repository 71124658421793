.influencers {
  /* background-color: #eeeeee; */
  /* background-color: orange; */
}

.influencer .line-1 {
  /* background-color: red; */
  display: flex;
  justify-content: center;
}

.influencer .swiper {
  /* background-color: blueviolet; */
  /* height: 600px; */
}

.gradient-cyan-container {
  position: absolute;
  width: 500px;
  height: 500px;
  opacity: 0.5;
  transform: scale(1);
  right: 0;
  bottom: -100px;
  filter: blur(80px);
}
.gradient-cyan {
  background: rgb(79, 217, 167);
  background: radial-gradient(
    circle,
    rgba(79, 217, 167, 0.4023984593837535) 36%,
    rgba(255, 255, 255, 0) 100%
  );
}

.influencer .line-1 h3 {
  color: var(--color-purple);
  font-size: 20px;
  line-height: 24.2px;
  font-weight: 600;
  text-transform: uppercase;
}

.influencer .line-2 {
  /* background-color: green; */
  margin-top: 21px;
  display: flex;
  justify-content: center;
}

.influencer .line-2 p {
  color: var(--color-subheading);
  font-size: 40px;
  font-weight: 700;
  line-height: 48.41px;
}

.pills-container {
  /* background-color: red; */
  margin-top: 30px;
  display: flex;
  justify-content: center;
  gap: 12px;
}

.pill {
  /* width: 127px; */
  padding: 0 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  border-radius: 40px;
  background-color: white;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.06);
  cursor: pointer;
}

/* .influencer-carousel {
  margin-top: 48px;
} */

@media only screen and (max-width: 640px) {
  .influencer-card {
    height: 100px;
  }
}

@media only screen and (max-width: 768px) {
  .influencer-card {
    height: 300px;
  }
}

@media only screen and (max-width: 1024px) {
  .influencer-card {
    height: 700px;
  }
}

@media only screen and (min-width: 640px) {
  #influencer-card {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }
}
