.brands {
  /* background-color: #eeeeee; */
  /* padding: 1rem 5.5rem 6rem 5.5rem; */
}

/* #brands-container {
  background-image: url(../../assets/images/gradient-2.png);
  background-size: cover;

} */

.brands .line-1 {
  /* background-color: red; */
  display: flex;
  justify-content: center;
}

.brands .line-1 h3 {
  color: var(--color-purple);
  font-size: 20px;
  line-height: 24.2px;
  font-weight: 600;
  text-transform: uppercase;
}

.brands .line-2 {
  /* background-color: green; */
  margin-top: 21px;
  display: flex;
  justify-content: center;
}

.brands .line-2 p {
  color: var(--color-subheading);
  font-size: 40px;
  font-weight: 700;
  line-height: 48.41px;
}

.brands .line-3 {
  margin-top: 63px;
  /* background-color: aqua; */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 56px;
  align-items: center;
}

#help-you-box {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

@media screen and (max-width: 1024px) {
  #help-you-box {
    box-shadow: none;
  }
}

.brands-desc > div > div:nth-child(2) > h3 {
  /* font-size: 26px; */
  line-height: 31px;
  color: #828282;
}
.brands-desc > div > div:nth-child(2) > h3 > span {
  /* font-size: 26px; */
  font-weight: bold;
  color: var(--color-subheading);
  line-height: 31px;
}

#au-container {
  margin-top: 25px;
  /* background-color: chocolate; */
  display: flex;
}

#au-container > div:nth-child(2) {
  position: relative;
  right: 25px;
}
#au-container > div:nth-child(3) {
  position: relative;
  right: 50px;
}
#au-container > div:nth-child(4) {
  position: relative;
  right: 75px;
}
#au-container > div:nth-child(5) {
  position: relative;
  right: 100px;
}
